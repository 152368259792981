import Cookies from 'js-cookie'
import {KeycloakProfile} from 'keycloak-js'
import {authLoginPath, authLogoutPath} from '../auth.paths'
import {AuthenticatedUser, Authenticator, Permission} from './Authenticator'

export const DEV_AUTH_COOKIE_NAME = 'dev-auth'
export const IS_LOGGED_IN_VALUE = 'logged-in'

export class DevAuthenticator implements Authenticator {
  init(done: () => void) {
    const loggedIn = Cookies.get(DEV_AUTH_COOKIE_NAME)
    console.warn('Using the NoopAuthenticator: loggedIn = ', loggedIn !== undefined)
    done()
  }

  isInitialized(): boolean {
    return true
  }

  getProfile(): KeycloakProfile | null {
    return null
  }

  getToken(): string | undefined {
    return undefined
  }

  getTokenType(): string | undefined {
    return undefined
  }

  hasGroup(groupName: string): boolean {
    return true
  }

  hasRole(roleName: string[]) {
    return true
  }

  getUserAttribute(attributeName: string) {
    return null
  }

  hasPermission(permission: Permission): boolean {
    return false
  }

  isUserName(userName: string[]) {
    return true
  }

  isAuthenticated(): boolean {
    return Cookies.get(DEV_AUTH_COOKIE_NAME) === IS_LOGGED_IN_VALUE
  }

  login(): void {
    const currentPath = window.location.pathname + window.location.search
    window.location.href = authLoginPath(currentPath)
  }

  logout(): void {
    window.location.pathname = authLogoutPath()
  }

  currentUser(): AuthenticatedUser {
    return {
      name: 'Development User 1',
      email: 'test@test.com',
      id: '',
    }
  }
}
