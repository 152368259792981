import {BaseCssProps, Box, createFelaConfig, renderRebootCss} from '@indoqa/style-system'
import {
  authenticator,
  authLoginPath,
  authLogoutPath,
  DevLoginPage,
  DevLogoutPage,
  ProtectedComponent,
} from '@oegbv/ui-shared'
import {createRenderer} from 'fela'
import {createBrowserHistory} from 'history'
import * as React from 'react'
import {RendererProvider, ThemeProvider} from 'react-fela'
import {Route, Router, Switch} from 'react-router-dom'
import {RedocStandalone} from 'redoc'
import {apis} from './apis'
import {Header} from './Header'
import {Overview} from './Overview'
import theme from './theme'

const appHistory = createBrowserHistory()
const felaConfig = createFelaConfig()
const renderer = createRenderer(felaConfig)

export const paths = {
  root: '/',
}

const baseCssProps: BaseCssProps = {
  spacing: {
    space1: theme.spacing.space1,
    space2: theme.spacing.space2,
  },
  fontSizes: {
    text: theme.fontSizes.text,
    h1: theme.fontSizes.veryBig,
    h2: theme.fontSizes.big,
    h3: theme.fontSizes.text,
  },
  fontStyles: {
    base: theme.fontStyles.base,
    alt: theme.fontStyles.alt,
  },
  links: {
    base: theme.colors.primary,
    hover: theme.colors.primary,
    active: theme.colors.primary,
    visited: theme.colors.primary,
  },
  colors: {
    text: theme.colors.text,
  },
}

export const App: React.FC = () => {
  React.useLayoutEffect(() => {
    renderRebootCss(renderer, baseCssProps)
  }, [])

  const routes = apis
    .filter(
      (api) =>
        authenticator.isUserName(api.users) ||
        authenticator.hasRole(api.roles) ||
        authenticator.hasPermission(api.permissions)
    )
    .map((api) => {
      return (
        <Route exact path={api.path} key={api.path}>
          <ProtectedComponent>
            <div>
              <Header />
              <RedocStandalone specUrl={api.openApiPath} />
            </div>
          </ProtectedComponent>
        </Route>
      )
    })

  return (
    <RendererProvider renderer={renderer}>
      <Router history={appHistory}>
        <ThemeProvider theme={theme}>
          <Box fullHeight>
            <Switch>
              {routes}
              <Route exact path={authLoginPath()} component={DevLoginPage} />
              <Route exact path={authLogoutPath()} component={DevLogoutPage} />
              <Route>
                <Overview />
              </Route>
            </Switch>
          </Box>
        </ThemeProvider>
      </Router>
    </RendererProvider>
  )
}
