import {AlphaFeatures, InitialState} from './InitialState'

export const getInitialState = (): InitialState => {
  const initialState = window['__INITIAL_STATE__'] as InitialState | null
  if (initialState) {
    return initialState
  }

  // provide initial state for development (this code should be eliminated by webpack)
  if (process.env.NODE_ENV !== 'production') {
    return {
      conf: {
        entryPoint: null,
        auth: {
          url: 'https://sso-integration.apps.ocp.3sit.at/auth/',
          realm: 'oegb-verlag',
          clientId: 'content-pools',
        },
      },
      links: {
        digital: {
          reqParam: 'ep=digital',
          base: '/',
        },
        ar: {
          reqParam: 'ep=ar',
          base: '/',
        },
        drda: {
          reqParam: 'ep=drda',
          base: '/',
        },
        kvs: {
          reqParam: 'ep=kvs',
          base: '/',
        },
        soz: {
          reqParam: 'ep=soz',
          base: '/',
        },
      },
      it: true,
      alphaFeatures: AlphaFeatures.ALL,
      commitShortId: 'UNKNOWN',
      commitTime: 'UNKNOWN',
      version: 'UNKNOWN',
      env: 'localhost',
    }
  }
  // this error should never be thrown if there is a INITIAL_STATE available
  throw Error('The initial state is not set correctly')
}
