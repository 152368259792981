import {BaseColors, BaseTheme, baseTheme} from '@indoqa/style-system'

interface Colors extends BaseColors {
  readonly primary: string,
  readonly primaryLight: string,
  readonly secondary: string,
  readonly accent: string,
  readonly bgContentEmphasised: string,
}

export interface Theme extends BaseTheme {
  readonly colors: Colors,
}

export const colors = {
  black: '#000000',
  grey_700: '#6A6A6A',
  grey_600: '#8A8A8A',
  grey_500: '#AAAAAA',
  grey_300: '#BBBDBE',
  grey_200: '#EBEBEB',
  grey_100: '#E3E3E3',
  white: '#ffffff',
  blue_900: '#003453', // shop text
  blue_800: '#033658', // shop primary
  blue_600: '#024F7F', // shop primary-light
  blue_500: '#5D8DC5', // KV von Markus Stefan
  blue_400: '#5ba2c3', // shop text blue
  blue_300: '#bbe9ff', // shop blue highlight
  blue_200: '#deebfd', // highlight Markus
  red_300: '#D33825', // shop line
  red_800: '#E30513', // logo color
  red_900: '#511d00',
  yellow_200: '#FBEBBA',
  green_800: 'rgb(0, 102, 33)',
  green_500: '#419127', // markus stefan color pallet
  green_200: '#B8D20D', // shop line
  violet_600: '#654393', // markus stefan color pallet
  cyan_600: '#3e8092',  // markus stefan color pallet
}

const theme: Theme = {
  ...baseTheme,
  breakpoints: baseTheme.breakpoints,
  colors: {
    primary: colors.blue_800,
    primaryLight: colors.blue_600,
    secondary: colors.blue_500,
    accent: colors.red_800,
    text: colors.black,
    bgContentEmphasised: colors.grey_100,
  },
}

export default theme
