import {authenticator} from '@oegbv/ui-shared'
import * as React from 'react'
import * as ReactDOM from 'react-dom'

import {App} from './app/App'

const rootEl = document.getElementById('app')

// support silent SSO login
authenticator.init(() => {
  ReactDOM.render(<App />, rootEl)

  if (module.hot) {
    module.hot.accept('./app/App', () => {
      const NextApp = require('./app/App.tsx').default
      ReactDOM.render(<NextApp/>, rootEl)
    })
  }
})
