import {Box} from '@indoqa/style-system'
import * as React from 'react'
import {useFela} from 'react-fela'
import {NavLink} from 'react-router-dom'
import {Theme} from './theme'

interface Props {
  to: string,
}

export const HeaderLink: React.FC<Props> = ({to, children}) => {
  const {theme} = useFela<Theme>()
  const style: any = {
    '& a': {
      textDecoration: 'none',
      fontWeight: 'bold',
    },
    '& a.active': {
      color: theme.colors.accent,
    },
  }
  return (
    <Box style={style} pl={2}>
      <NavLink to={to}>{children}</NavLink>
    </Box>
  )
}
