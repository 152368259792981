import {Box, Flex} from '@indoqa/style-system'
import * as React from 'react'
import {useHistory} from 'react-router'

interface Props {
  name: string,
  linkTo: string,
}

export const OverviewItem: React.FC<Props> = ({name, linkTo, children}) => {
  const history = useHistory()
  return (
    <Flex height={120} center bg="primary" color="white" direction="column" onClick={() => history.push(linkTo)} cursorPointer>
      <Box>
        <h1>{name}</h1>
      </Box>
      <Box px={2}>
        {children}
      </Box>
    </Flex>
  )
}
