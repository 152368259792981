import {
  BaseColors,
  BaseFontSizes,
  BaseFontStyles,
  BaseShadows,
  baseTheme,
  BaseTheme,
  BaseZIndexes,
  systemFonts,
} from '@indoqa/style-system'
import {IStyle} from 'fela'

export interface OegbvBaseFontSizes extends BaseFontSizes {
  readonly extraBig: number | string
}

export interface OegbvBaseFontStyles extends BaseFontStyles {
  readonly brand: IStyle
  readonly brandBold: IStyle
  readonly brandLight: IStyle
}

export interface OegbvBaseColors extends BaseColors {
  readonly primary: string
  readonly primaryLight: string
  readonly secondary: string
  readonly accent: string
  readonly info: string

  readonly textHighlight: string
  readonly textHighlightAlternative: string
  readonly textInverted: string
  readonly textInvertedHighlight: string
  readonly textSource: string
  readonly textReduced: string

  readonly icon: string
  readonly iconHighlighted: string
  readonly iconInverted: string
  readonly iconReduced: string

  readonly border: string

  readonly bgContentEmphasised: string
  readonly bgMarkedInSearchResult: string
  readonly bgMarkedInText: string
  readonly bgFussnote: string
  readonly bgStructureHover: string
  readonly bgStructureSelected: string
  readonly bgModalWindow: string
  readonly bgModalWindowVeil: string
  readonly bgModalWindowHeader: string
  readonly bgModalWindowFooter: string

  readonly bgScrollbarThumb: string
  readonly bgScrollbarThumbHover: string
  readonly bgScrollbarTrack: string
  readonly bgScrollbarTrackHover: string

  readonly bgCtaButton: string

  readonly contextMenu: string
}

export interface OegbvBaseBorders {
  border0: string
  border1: string
}

export interface OegbvBaseZIndexes extends BaseZIndexes {
  floatingButton: number
  modal: number
}

export interface OegbvBaseShadows extends BaseShadows {
  readonly contextMenu: string
}

export interface OegbvBaseTheme extends BaseTheme {
  readonly fontStyles: OegbvBaseFontStyles
  readonly fontSizes: OegbvBaseFontSizes
  readonly colors: OegbvBaseColors
  readonly borders: OegbvBaseBorders
  readonly shadows: OegbvBaseShadows
  readonly zIndexes: OegbvBaseZIndexes
}

export const oegbvBaseColors = {
  black: '#000000',
  grey_800: '#333333',
  grey_700: '#6A6A6A',
  grey_600: '#8A8A8A',
  grey_500: '#AAAAAA',
  grey_300: '#BBBDBE',
  grey_200: '#EBEBEB',
  grey_100: '#E3E3E3',
  white: '#ffffff',
  blue_900: '#003453', // shop text
  blue_800: '#033658', // shop primary
  blue_600: '#024F7F', // shop primary-light
  blue_500: '#5D8DC5', // KV von Markus Stefan
  blue_400: '#5ba2c3', // shop text blue
  blue_300: '#bbe9ff', // shop blue highlight
  blue_200: '#deebfd', // highlight Markus
  red_300: '#D33825', // shop line
  red_500: '#E30513', // logo color
  red_700: '#7b2c00', // text highlight
  red_900: '#511d00',
  yellow_200: '#FBEBBA',
  yellow_500: '#FFBC00',
  yellow_900: 'yellow',
  green_800: 'rgb(0, 102, 33)',
  green_500: '#419127', // markus stefan color pallet
  green_200: '#B8D20D', // shop line
  violet_600: '#654393', // markus stefan color pallet
  cyan_600: '#3e8092', // markus stefan color pallet
}

const brandFontFamily = systemFonts
// const brandFontFamily = `ConduitITCPro, ${systemFonts}`

const baseFontSizes: OegbvBaseFontSizes = {
  text: '18px',
  big: '1.2rem',
  veryBig: '1.4rem',
  extraBig: '1.6rem',
  small: '0.9rem',
  verySmall: '0.8rem',
}

const shadows = {
  shadow_1:
    '0 0.6px 0.8px rgba(0, 0, 0, 0.038), 0 1.6px 2.3px rgba(0, 0, 0, 0.055), 0 3.9px 5.4px rgba(0, 0, 0, 0.072), 0 13px 18px rgba(0, 0, 0, 0.11)',
}

export const oegbvBaseTheme: OegbvBaseTheme = {
  breakpoints: baseTheme.breakpoints,
  colors: {
    primary: oegbvBaseColors.blue_800,
    primaryLight: oegbvBaseColors.blue_600,
    secondary: oegbvBaseColors.blue_500,
    accent: oegbvBaseColors.red_500,
    info: oegbvBaseColors.green_500,

    text: oegbvBaseColors.grey_800,
    textHighlight: oegbvBaseColors.red_700,
    textHighlightAlternative: oegbvBaseColors.yellow_500,
    textInverted: oegbvBaseColors.white,
    textInvertedHighlight: oegbvBaseColors.blue_300,
    textSource: oegbvBaseColors.green_800,
    textReduced: oegbvBaseColors.grey_700,

    icon: oegbvBaseColors.blue_800,
    iconHighlighted: oegbvBaseColors.blue_800,
    iconInverted: oegbvBaseColors.white,
    iconReduced: oegbvBaseColors.grey_600,

    border: oegbvBaseColors.grey_300,

    bgContentEmphasised: oegbvBaseColors.grey_100,
    bgStructureHover: oegbvBaseColors.grey_200,
    bgStructureSelected: oegbvBaseColors.grey_100,
    bgMarkedInSearchResult: oegbvBaseColors.blue_200,
    bgMarkedInText: oegbvBaseColors.yellow_900,
    bgFussnote: oegbvBaseColors.blue_600,

    bgScrollbarThumb: oegbvBaseColors.grey_500,
    bgScrollbarThumbHover: oegbvBaseColors.grey_600,
    bgScrollbarTrack: oegbvBaseColors.grey_200,
    bgScrollbarTrackHover: oegbvBaseColors.grey_200,

    bgModalWindow: oegbvBaseColors.white,
    bgModalWindowVeil: 'rgba(255, 255, 255, 0.7)',
    bgModalWindowHeader: oegbvBaseColors.blue_600,
    bgModalWindowFooter: oegbvBaseColors.white,

    bgCtaButton: oegbvBaseColors.red_500,

    // contentTypeBookChapter: colors.blue_800,
    // contentTypeChapter: colors.blue_800,
    // contentTypeDefinition: colors.cyan_600,
    // contentTypeNews: colors.blue_800,
    // contentTypeQuestion: colors.blue_500,
    // contentTypeVideo: colors.blue_800,
    // contentTypeKv: colors.green_500,
    // contentTypeLaw: colors.violet_600,

    contextMenu: oegbvBaseColors.grey_300,
  },
  fontSizes: {
    ...baseFontSizes,
    verySmall: '10px',
  },
  fontStyles: {
    ...baseTheme.fontStyles,
    base: {
      ...baseTheme.fontStyles.base,
      lineHeight: 1.5,
    },
    brand: {
      fontFamily: brandFontFamily,
      fontWeight: 400,
    },
    brandBold: {
      fontFamily: brandFontFamily,
      fontWeight: 700,
    },
    brandLight: {
      fontFamily: brandFontFamily,
      fontWeight: 300,
    },
  },
  borders: {
    border0: 'none',
    border1: `solid 1px ${oegbvBaseColors.grey_100}`,
  },
  spacing: {
    space0: 0,
    space0_5: '0.125rem',
    space1: '0.25rem',
    space1_5: '0.375rem',
    space2: '0.5rem',
    space2_5: '0.625rem',
    space3: '0.75rem',
    space3_5: '0.875rem',
    space4: '1rem',
    space5: '1.25rem',
    space6: '1.5rem',
    space7: '1.75rem',
    space8: '2rem',
    space9: '2.25rem',
    space10: '2.5rem',
    space11: '2.75rem',
    space12: '3rem',
    space14: '3.5rem',
    space16: '4rem',
    space20: '5rem',
    space24: '6rem',
    space28: '7rem',
    space32: '8rem',
    space36: '9rem',
    space40: '10rem',
    space44: '11rem',
    space48: '12rem',
    space52: '13rem',
    space56: '14rem',
    space60: '15rem',
    space64: '16rem',
    space72: '18rem',
    space80: '20rem',
    space96: '24rem',
  },
  shadows: {
    ...baseTheme.shadows,
    contextMenu: shadows.shadow_1,
  },
  zIndexes: {
    ...baseTheme.zIndexes,
    floatingButton: 1000,
    modal: 900,
  },
}
