import {Flex} from '@indoqa/style-system'
import Cookies from 'js-cookie'
import * as React from 'react'
import {useHistory} from 'react-router-dom'
import {useQueryParam} from '../hooks'
import {authQueryParams} from './auth.paths'
import {DEV_AUTH_COOKIE_NAME} from './authenticators/DevAuthenticator'

export const DevLogoutPage: React.FC = () => {
  const history = useHistory()
  const redirectParam = useQueryParam(authQueryParams.redirect)
  const logout = () => {
    Cookies.remove(DEV_AUTH_COOKIE_NAME)
    const redirectPath = redirectParam ?? '/'
    history.push(redirectPath)
  }
  return (
    <Flex fullWidth fullHeight center>
      <button onClick={() => logout()}>Logout</button>
    </Flex>
  )
}
