import Cookies from 'js-cookie'

const COOKIE_BEARER_TOKEN = '_bt'

export const writeBearerTokenCookie = (token?: string) => {
  if (token) {
    Cookies.set(COOKIE_BEARER_TOKEN, token, {sameSite: 'strict'})
  }
}

export const removeBearerTokenCookie = () => {
  Cookies.remove(COOKIE_BEARER_TOKEN, {sameSite: 'strict'})
}
