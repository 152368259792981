import queryString from 'query-string'
import {Authenticator} from './authenticators/Authenticator'
import {ERechtAuthenticator, KEY_ERECHT_TOKEN} from './authenticators/ERechtAuthenticator'
import {KeycloakAuthenticator} from './authenticators/KeycloakAuthenticator'

const getInstance = (): Authenticator => {
  // if (process.env.NODE_ENV === 'development') {
  //   return new DevAuthenticator()
  // }

  // check if it is a eRecht login
  const queryParams = queryString.parse(window.location.search)
  let token = queryParams['token']

  // if there is no token in the request, try to use a locally stored one
  if (!token) {
    token = window.localStorage.getItem(KEY_ERECHT_TOKEN)
  }

  // use the eRecht authenticator if there is a token available
  if (token && !Array.isArray(token)) {
    return new ERechtAuthenticator(token)
  }

  // use Keycloak for authentication
  return new KeycloakAuthenticator()
}

export const authenticator = getInstance()
