import * as React from 'react'

export interface Dimensions {
  headerHeight: number
  footerHeight: number
  navWidth: number
}

export interface AdminLayoutContextValue {
  navShown: boolean
  dimensions: Dimensions
  scrollToTop: Date
  toggleNav: () => void
  enableNav: () => void
  disableNav: () => void
  setHeaderHeight: (height: number) => void
  setFooterHeight: (height: number) => void
  setNavbarWidth: (height: number) => void
  setScrollToTop: (value: Date) => void
}

export const AdminLayoutContext = React.createContext<any>(null)
