import queryString from 'query-string'
import {useLocation} from 'react-router-dom'

export const useQueryParam = (name: string): string | null | undefined => {
  const location = useLocation()
  const value = queryString.parse(location.search)[name]
  if (!value) {
    return value
  }
  if (value instanceof Array) {
    return value[0]
  }
  return value
}
