import * as React from 'react'
import {authenticator} from './Authenticator'

interface Props {
  groupName?: string
  disableForcedLogin?: boolean
}

export const ProtectedComponent: React.FC<Props> = ({groupName, disableForcedLogin, children}) => {
  if (!authenticator.isAuthenticated()) {
    if (!disableForcedLogin) {
      authenticator.login()
    }
    return <div />
  }
  if (groupName && !authenticator.hasGroup(groupName)) {
    if (process.env.NODE_ENV === 'development') {
      return <div>Keine Berechtigung für die Gruppe {groupName}.</div>
    }
    return <div />
  }
  return <>{children}</>
}
