import {Box, Flex, HeaderFlex} from '@indoqa/style-system'
import {authenticator} from '@oegbv/ui-shared'
import * as React from 'react'
import {Link} from 'react-router-dom'
import {apis} from './apis'
import {paths} from './App'
import {ReactComponent as Logo} from './digital-logo-small.svg'
import {HeaderLink} from './HeaderLink'

export const Header: React.FC = () => {
  const links = apis
    .filter(
      (api) =>
        authenticator.isUserName(api.users) ||
        authenticator.hasRole(api.roles) ||
        authenticator.hasPermission(api.permissions)
    )
    .map((api) => {
      return (
        <React.Fragment key={api.path}>
          <HeaderLink to={api.path}>{api.name}</HeaderLink>
        </React.Fragment>
      )
    })
  return (
    <HeaderFlex
      fullWidth
      bg="bgContentEmphasised"
      px={4}
      height={45}
      alignItems="center"
      justifyContent="space-between"
    >
      <Box height={20} width={175}>
        <Link to={paths.root}>
          <Logo />
        </Link>
      </Box>
      <Flex fullHeight center>
        {links}
      </Flex>
    </HeaderFlex>
  )
}
