import jwt_decode from 'jwt-decode'
import {KeycloakProfile} from 'keycloak-js'
import {AuthenticatedUser, Authenticator, Permission} from './Authenticator'

interface Payload {
  docset?: string | null
  partnerId?: string | null
  slid?: string | null
  username?: string | null
}

export const KEY_ERECHT_TOKEN = 'ert'

const getPayload = (token: string): Payload | null => {
  try {
    return jwt_decode(token)
  } catch (e) {
    return null
  }
}

/*
 * How to test the eRechtAuthenticator:
 *
 * local:
 *  - go to http://digital.oegbv.localhost:3505/kvsystem/erecht-login-demo
 *  - enter a valid token (see https://jira.oegbverlag.at/browse/NTE-831 to find sample tokens or how to retrieve one)
 */
export class ERechtAuthenticator implements Authenticator {
  private _token: string | null
  private _payload: Payload | null

  constructor(token: string) {
    this._token = token
    this._payload = getPayload(token)
  }

  init(done: () => void) {
    if (this._token) {
      window.localStorage.setItem(KEY_ERECHT_TOKEN, this._token)
    }
    done()
  }

  isInitialized(): boolean {
    return true
  }

  getProfile(): KeycloakProfile | null {
    return null
  }

  getToken(): string | undefined {
    return this._token || undefined
  }

  getTokenType(): string | undefined {
    return 'erecht'
  }

  hasGroup(groupName: string): boolean {
    return false
  }

  hasRole(roleName: string[]) {
    return false
  }

  getUserAttribute(attributeName: string) {
    return null
  }

  hasPermission(permission: Permission) {
    if (permission === Permission.KVSYSTEM) {
      return true
    }
    return false
  }

  isUserName(userName: string[]) {
    return false
  }

  isAuthenticated(): boolean {
    return this._payload !== null
  }

  login(): void {
    // do nothing
  }

  logout(): void {
    window.localStorage.removeItem(KEY_ERECHT_TOKEN)
    this._token = null
    this._payload = null
    // reload the application -> the eRecht token should then be gone because it's only kept here as member variable
    window.location.pathname = '/'
  }

  currentUser(): AuthenticatedUser {
    return {
      name: this._payload?.username || 'User',
      email: 'email@unknown',
      id: '',
    }
  }
}
