import {Box, Col, ColRow, Grid} from '@indoqa/style-system'
import {authenticator, ProtectedComponent} from '@oegbv/ui-shared'
import * as React from 'react'
import {apis} from './apis'
import {Header} from './Header'
import {OverviewItem} from './OverviewItem'

export const Overview: React.FC = () => {
  const itemCols = apis
    .filter(
      (api) =>
        authenticator.isUserName(api.users) ||
        authenticator.hasRole(api.roles) ||
        authenticator.hasPermission(api.permissions)
    )
    .map((api) => {
      return (
        <Col size={4} key={api.path}>
          <OverviewItem name={api.name} linkTo={api.path}>
            {api.description}
          </OverviewItem>
        </Col>
      )
    })
  return (
    <ProtectedComponent>
      <div>
        <Header />
        <Box px={2} pt={12} fullWidth>
          <Grid maxWidth={950} center spacing={'3rem'}>
            <ColRow>{itemCols}</ColRow>
          </Grid>
        </Box>
      </div>
    </ProtectedComponent>
  )
}
