import {Flex} from '@indoqa/style-system'
import Cookies from 'js-cookie'
import * as React from 'react'
import {useHistory} from 'react-router-dom'
import {useQueryParam} from '../hooks/use-query-param/useQueryParam'
import {authQueryParams} from './auth.paths'
import {DEV_AUTH_COOKIE_NAME, IS_LOGGED_IN_VALUE} from './authenticators/DevAuthenticator'

export const DevLoginPage: React.FC = () => {
  const history = useHistory()
  const redirectParam = useQueryParam(authQueryParams.redirect)
  const login = () => {
    Cookies.set(DEV_AUTH_COOKIE_NAME, IS_LOGGED_IN_VALUE)
    const redirectPath = redirectParam ?? '/'
    history.push(redirectPath)
  }
  return (
    <Flex fullWidth fullHeight center>
      <button onClick={() => login()}>Login</button>
    </Flex>
  )
}
